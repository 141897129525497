@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "fonts";

body {
  font-family: 'Jost', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  #logo {
    width: 10rem;
  }
  .on-desktop{
    @media screen and (max-width: 700px) {
      display: none !important;
    }
  }
  .on-mobile{
    display: none !important;
    @media screen and (max-width: 700px) {
      display: block !important;
    }
  }

  section{
    position: relative;
    height: 100vh;
    min-height: 750px;
    width: 100%;
    @media screen and (max-width: 700px) {
      min-height: 100vh;
      height: auto;
    }
    &.flex-center{
      display: flex;
      align-items: center;
      &.mobile-padding-bottom{
        @media screen and (max-width: 500px) {
          padding-bottom: 30px;
        }
      }
      &.flex-center-v{
        justify-content: center;
        &.with-bad{
          padding-bottom: 30px;
        }
      }

      .bad-text{
        position: absolute;
        bottom: -30px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #FFFFFF;
        opacity: 0.7;
        font-weight: bold;
        width: 100%;
        text-align: center;
        @media screen and (max-width: 900px) {
          bottom: -160px;
        }
        @media screen and (max-width: 500px) {
          font-size: 10px;
        }
      }


      @media screen and (max-width: 700px) {
        &.mobile-block{
          display: block;
          overflow: hidden;
        }

      }

    }

    .bg-404{
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 10%;
      background: url(../img/404.png) no-repeat 50% 50%;
      background-size: cover;
      @media screen and (max-width: 500px) {
        background: url(../img/m404.png) no-repeat 50% 10%;
        background-size: cover;
        top: 0;
      }
    }

    &.bad-page-section{
      @media screen and (max-width: 700px) {
        padding-bottom: 100vw;
        position: relative;
        display: block;
        height: auto;
        min-height: 0;

        .container{
          .mobile-center{
            position: absolute;
            display: block;
            bottom: calc(-100vw + 40px);
            width: 100%;
          }
        }
      }
    }
  }
  .container {
    position: relative;
    width: 900px;
    //margin: 0 auto;
    &.small-top-padding{
      padding-top: 50px;
      @media screen and (max-width: 700px) {
        padding-top: 0;
        margin: 0 auto;
        margin-top: 66px;
        &.bad-page-block{
          padding-top: 120px;
        }
      }

    }
    &.links{
      position: absolute;
      left: calc(50% - 450px);
      bottom: 15px;
      color: #fff;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.5px;
      opacity: 0.7;
    }
    @media screen and (max-width: 900px) {
      width: calc(100% - 32px);
      &.links{
        width: calc(100% - 32px);
        left: 16px;
        bottom: 15px;
      }
    }
    @media screen and (max-width: 700px) {
      width: calc(100% - 32px);
      &.links{
        position: relative;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        bottom: auto;
        width: calc(100% - 32px);
        left: 16px;
        //bottom: 15px;
      }
    }


    &.no-events{
      pointer-events: none;
      a{
        pointer-events: all;
      }
    }
  }

  br.hide-on-desktop{
    display: none;
    @media screen and (max-width: 700px) {
      display: block;
    }
  }

  .mobile-center{
    @media screen and (max-width: 700px) {
      text-align: center;
    }
  }

  header{
    position: absolute;
    display: block;
    top: 32px;
    width: 100%;
    z-index: 100;
    @media screen and (max-width: 700px) {
      top: 0;
    }
    .container{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 30px auto;

      .header-logo{
        position: relative;
        display: block;
        left: -72px;
        top: -36px;
        @media screen and (max-width: 700px) {
          left: -51px;
          top: -30px;
          img{
            width: 300px;
            height: auto;
          }
        }
      }

      #burger{
        position: absolute;
        right: 0;
        cursor: pointer;
        @media screen and (max-width: 700px) {
          position: absolute;
          right: 0;
        }
      }
      #main-menu{
        position: absolute;
        display: none;
        right: -26px;
        top: -26px;
        padding: 26px;
        padding-right: 8px;
        border-radius: 38px;
        background: #fff;
        width: 242px;
        box-sizing: border-box;
        font-size: 9px;
        line-height: 12px;
        color: #000;
        @media screen and (max-width: 980px) {
          right: 0;
        }
        @media screen and (max-width: 800px) {
          right: -11px;
        }

        &.active{
          display: block;
        }
        a{
          position: relative;
          display: block;
          font-size: 16px;
          color: #0A4DAA;
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          margin-bottom: 16px;
          &:hover{
            opacity: 0.7;
          }
          span{
            border-bottom: solid 1px rgba(10,77,170,0.1);
          }
        }
        #close-menu{
          position: absolute;
          display: block;
          right: 26px;
          top: 26px;
          cursor: pointer;
        }
      }
    }

  }
  footer{
    position: relative;
    overflow: hidden;
    background: #152337;
    @media screen and (max-width: 700px) {
      height: 212px;
      overflow: hidden;
    }
    .container{
      display: flex;
      justify-content: space-between;
      margin: 30px auto;
      @media screen and (max-width: 700px) {
        margin: 16px auto;
        display: block;
      }
    }

    .footer-title{
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      font-family: 'Gotham Pro';
      color: #fff;
      br.mobile-br{
        display: none;
      }
      @media screen and (max-width: 700px) {
        position: absolute;
        display: block;
        font-size: 14px;
        line-height: 17px;
        padding-top: 8px;
        br{
          display: none;
        }
        br.mobile-br{
          display: block;
        }
      }
      small{
        position: relative;
        display: block;
        margin-top: 14px;
        color: rgba(255, 255, 255, 0.63);
        font-size: 9px;
        line-height: 12px;
        @media screen and (max-width: 700px) {
          position: absolute;
          display: block;
          top: 150px;
          margin-top: 0;
        }
      }
    }
    img{
      @media screen and (max-width: 700px) {
        position: absolute;
        right: 0;
        top: 0;
        width: 90px;
      }
    }
    .footer-info{
      padding-top: 10px;
      max-width: 300px;
      font-size: 9px;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.63);
      @media screen and (max-width: 700px) {
        position: absolute;
        display: block;
        top: 120px;
        max-width: 100%;
        padding-top: 0;
      }
    }
    .footer-menu{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-top: 10px;
      max-width: 170px;
      height: 0;
      @media screen and (max-width: 700px) {
        position: absolute;
        display: flex;
        justify-content: space-between;
        //flex-direction: row-reverse;
        max-width: 100%;
        width: 100%;
        top: 65px;
      }
      a{
        position: relative;
        display: block;
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        font-size: 9px;
        line-height: 12px;
        font-family: 'Gotham Pro';
        margin-bottom: 11px;
        &:hover{
          opacity: 0.7;
        }
        span{
          border-bottom: solid 1px rgba(255,255,255,0.2);
        }
      }
    }
  }

  a.yellow-btn{
    position: relative;
    display: inline-block;
    width: auto;
    padding: 0 45px;
    color: #000;
    font-size: 20px;
    line-height: 59px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    background: #E4DE7D;
    box-shadow: 0px 4px 24px rgba(255, 249, 155, 0.53);
    border-radius: 60px;
    margin-top: 47px;

    &:hover{
      box-shadow: none;
      opacity: 0.7 !important;
    }
  }

  .fade-in-up{
    transform: translateY(30px);
    opacity: 0;
    animation: fadeInUp 0.5s ease-out forwards;
    &.delay100{
      animation-delay: 100ms;
    }
    &.delay200{
      animation-delay: 200ms;
    }
    &.delay300{
      animation-delay: 300ms;
    }
    &.delay400{
      animation-delay: 400ms;
    }
    &.delay500{
      animation-delay: 500ms;
    }
    &.delay600{
      animation-delay: 600ms;
    }
    &.delay700{
      animation-delay: 700ms;
    }
    &.delay800{
      animation-delay: 800ms;
    }
    &.delay900{
      animation-delay: 900ms;
    }
    &.delay1000{
      animation-delay: 1000ms;
    }
    &.delay1100{
      animation-delay: 1100ms;
    }
  }
  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(30px); }
    100% { opacity: 1; transform: translateY(0px); }
  }

  #home-ripple-bg{
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 750px;
  }
  .intro-title{
    font-size: 90px;
    line-height: 95px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    @media screen and (max-width: 900px) {
      font-size: 70px;
      line-height: 75px;
    }
    @media screen and (max-width: 700px) {
      font-size: 40px;
      line-height: 48px;
      a{
        margin: 0 auto;
        margin-top: 92px;
        width: 222px;
        position: relative;
        display: block;
        box-sizing: border-box;
      }
    }
    small{
      position: relative;
      display: inline-block;
      padding: 0 15px;
      font-size: 18px;
      line-height: 40px;
      background: rgba(255, 255, 255, 0.21);
      margin-bottom: 14px;
      border-radius: 20px;
      font-weight: 300;
      letter-spacing: 1px;
      @media screen and (max-width: 700px) {
        padding: 0 12px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .modal{
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left :0;
    top: 0;
    z-index: 1000;
    background: rgba(0, 37, 88, 0.68);

    &.active{
      display: flex;
    }

    img{
      position: absolute;
      display: block;
      pointer-events: none;
    }
    .modal-block{
      position: relative;
      display: block;
      background: #FFFFFF;
      backdrop-filter: blur(3px);
      border-radius: 43px;
      padding: 60px;
      padding-bottom: 162px;
      width: 100%;
      max-width: 900px;
      box-sizing: border-box;
      min-height: 447px;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.5px;
      &.not-doctor{
        display: none;
      }
      @media screen and (max-width: 900px) {
        width: calc(100% - 32px);
        padding: 30px;
        min-height: 0;
        height: auto;
        font-size: 18px;
        line-height: 22px;
      }

      .modal-title{
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        @media screen and (max-width: 900px) {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 3px;
        }
      }
      .modal-buttons{
        position: absolute;
        display: block;
        bottom: 60px;
        @media screen and (max-width: 900px) {
          position: relative;
          display: block;
          bottom: auto;
          margin-top: 15px;
          text-align: center;
        }

        a{
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 237px;
          height: 59px;
          line-height: 55px;
          margin-right: 21px;
          color: #000;
          text-decoration: none;
          text-align: center;
          border: #E4DE7D solid 2px;
          box-sizing: border-box;
          background: #E4DE7D;
          box-shadow: 0px 4px 24px rgba(255, 249, 155, 0.53);
          border-radius: 60px;
          &:hover{
            opacity: 0.7;
          }
          &.bw-border{
            background: none;
            box-shadow: none;
            border: #000 solid 2px;
            box-sizing: border-box;
          }
          @media screen and (max-width: 900px) {
            width: 256px;
            margin-right: 0;
            z-index: 1;
            &:first-child{
              margin-bottom: 10px;
            }
          }
        }
      }

      .modal-form{
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 36px;
        @media screen and (max-width: 900px) {
          margin-bottom: 40px;
        }
        @media screen and (max-width: 700px) {
          display: block;
        }

        .modal-form-input{
          position: relative;
          height: 34px;
          width: 30%;
          border-bottom: #AFAFAF solid 2px;
          @media screen and (max-width: 700px) {
            width: 100%;
            margin-bottom: 10px;
          }
          &.error{
            border-bottom: red solid 2px;
          }
          &.fill{
            border-bottom: #0A4DAA solid 2px;
          }
          input{
            position: relative;
            display: block;
            width: 100%;
            height: 34px;
            line-height: 34px;
            outline: none;
            border: 0;
            color: #152337;
            font-size: 22px;
            background: none;
            font-family: 'Jost', sans-serif;

            &::-webkit-input-placeholder {color:#AFAFAF;}
            &::-moz-placeholder          {color:#AFAFAF;}/* Firefox 19+ */
            &:-moz-placeholder           {color:#AFAFAF;}/* Firefox 18- */
            &:-ms-input-placeholder      {color:#AFAFAF;}
          }
        }
      }
    }

    .for-doctor-modal img{
      bottom: -84px;
      height: 114px;
      right: -79px;
    }
    &.sog-modal img{
      bottom: -153px;
      height: 492px;
      right: -226px;
      @media screen and (max-width: 900px) {
        bottom: -118px;
        height: 233px;
        right: -59px;
      }
    }
    &.rules-modal img{
      bottom: -119px;
      height: 292px;
      right: -123px;
      @media screen and (max-width: 900px) {
        bottom: -118px;
        height: 233px;
        right: -59px;
      }
    }
    &.nya-modal img{
      bottom: -119px;
      height: 292px;
      right: -123px;
      @media screen and (max-width: 900px) {
        bottom: -118px;
        height: 233px;
        right: -59px;
      }
    }
    .not-doctor img{
      bottom: -118px;
      height: 463px;
      right: -99px;
      @media screen and (max-width: 700px) {
        bottom: -118px;
        height: 233px;
        right: -89px;
      }
    }
  }


  .text-title{
    position: relative;
    display: block;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 27px;
    letter-spacing: 1px;
    color: #fff;
    small{
      position: relative;
      display: block;
      margin-top: 20px;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: 1px;
      @media screen and (max-width: 700px) {
        font-size: 16px;
        line-height: 23px;
        margin-top: 10px;
      }
    }
    &.size48{
      font-size: 48px;
      line-height: 52px;
    }
    @media screen and (max-width: 900px) {
      &.size48{
        br{
          display: none;
        }
      }
    }
    @media screen and (max-width: 700px) {
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 15px;
      &.size48{
        font-size: 30px;
        line-height: 34px;
        br{
          display: none;
        }
      }
    }
    &.small-text-title{
      font-size: 40px;
      line-height: 58px;
      @media screen and (max-width: 700px) {
        font-size: 35px;
        line-height: 39px;
      }
    }
    &.fontsize36{
      font-size: 36px;
      line-height: 43px;
      @media screen and (max-width: 700px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }

  .bad-bg{
    top: 0;
    display: block;
    font-size: 0;
    left: calc(50% - 100px);
    position: absolute;
    width: 809px;
    height: 100%;
    min-height: 750px;
    @media screen and (max-width: 700px) {
      background: url(../img/bad_bg1_m.png) no-repeat 0 100%;
      background-size: 100% auto;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 0;
      height: 100%;
    }
    img{
      width: 100%;
      position: absolute;
      bottom: 0;
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    &.vismed{
      width: 886px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg2_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
    &.vismed5{
      width: 886px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg5_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
    &.vismed6{
      width: 886px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg6_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
    &.vismed7{
      width: 886px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg7_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
    &.retinorm{
      width: 1006px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg3_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
    &.vision{
      width: 1006px;
      left: calc(50% - 120px);
      @media screen and (max-width: 700px) {
        width: 100%;
        left:0;
        background: url(../img/bad_bg4_m.png) no-repeat 0 100%;
        background-size: 100% auto;
      }
    }
  }

  .text-block{
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 25px;
    height: auto;
    letter-spacing: 0.5px;
    color: #fff;
    strong{
      letter-spacing: 1px;
    }
    @media screen and (max-width: 700px) {
      font-size: 16px;
      line-height: 18px;
    }

    .text-block-scrollbar{
      background: url(../img/scrollbar.svg) repeat-y 26px 0;
      background-size: 3px 100%;
      display: block;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0;
      width: 52px;
      @media screen and (max-width: 700px) {
        right: -15px;
      }
      .text-block-scrollbar-thumb{
        position: absolute;
        display: block;
        width: 52px;
        height: 63px;
        left: 0;
        top: 0;
        background: url(../img/scroller.png) no-repeat 50% 50%;
        background-size: contain;
      }
    }
    &.bad-text-block{
      width: 636px;
      margin-bottom: -20px;
      @media screen and (max-width: 700px) {
        width: auto;
      }

      a{
        letter-spacing: 1px;
        text-decoration-line: underline;
        font-size: 20px;
        line-height: 24px;
        color: #E4DE7D;
      }
      .text-block-content{
        max-height: 300px;
        padding-right: 57px;
        @media screen and (max-width: 700px) {
          padding-right: 33px;
          max-height: 367px;
          height: auto;
        }
      }
      .text-block-scrollbar-thumb{
        width: 52px;
        height: 53px;
        background: url(../img/scroller_star.png) no-repeat 50% 50%;
        background-size: contain;
        margin-left: 3px;
      }

      .show-more{
        position: relative;
        display: inline-block;
        letter-spacing: 1px;
        border-bottom: solid 1px #E4DE7D;
        font-size: 20px;
        line-height: 24px;
        color: #E4DE7D;
        cursor: pointer;
        margin: 10px 0;
      }
      .show-more-content{
        display: none;
        ul{
          margin: 10px 0;
        }
        p{
          margin: 10px 0;
        }
        small{
          opacity: 1;
          font-size: 14px;
          line-height: 18px;
          margin-top: 0;
          sup{
            font-size: 11px;
            line-height: 15px;
          }
        }
      }

      small{
        opacity: 0.7;
      }
    }

    .text-block-content{
      position: relative;
      display: block;
      height: 100%;
      max-height: 318px;
      overflow-y: scroll;
      padding-right: 97px;
      &.no-scroll{
        height: auto;
        overflow-y: hidden;
        max-height: inherit;
      }
      @media screen and (max-width: 700px) {
        padding-right: 33px;
        max-height: calc(100vh - 320px);
        &.good-text{
          max-height: 100000px;
          overflow-y: hidden;
        }
        img{
          width: 100%;
        }
      }

      sup{
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        line-height: 18px;
        @media screen and (max-width: 700px) {
          font-size: 8px;
          line-height: 14px;
        }
      }
      small{
        position: relative;
        display: block;
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      strong{
        font-size: 22px;
        line-height: 25px;
        @media screen and (max-width: 700px) {
          font-size: 18px;
          line-height: 18px;
        }
      }
      p:first-child{
        margin-top: 0;
      }
      p:last-child{
        margin-bottom: 0;
      }
    }
    &.with-scroll{
    }
  }

  .what-items{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    @media screen and (max-width: 700px) {
      display: block;
      margin-left: -15px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: calc(100% + 30px);
      height: 480px;
      margin-bottom: -70px;
      &::-webkit-scrollbar{
        opacity: 0;
        visibility: hidden;
      }
    }

    .what-item{
      width: 441px;
      height: 323px;
      background: #FFFFFF;
      border-radius: 38px;
      padding: 40px 33px;
      font-size: 20px;
      line-height: 25px;
      color: #152337;
      letter-spacing: 1px;
      box-sizing: border-box;
      b{
        position: relative;
        display: block;
        color: #E2E2E2;
        font-size: 35px;
        line-height: 44px;
      }
      @media screen and (max-width: 900px) {
        width: 48%;
        height: 373px;
        br{
          display: none;
        }
      }
      @media screen and (max-width: 700px) {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        padding: 30px;
        width: 313px;
        height: 395px;
        font-size: 21px;
        line-height: 25px;
        margin-right: 15px;
        margin-top: 16px;
        &:first-child{
          margin-left: 15px;
        }
      }

      img{
        display: block;
      }
      &:first-child{
        img{
          position: absolute;
        }
        img:nth-child(1){
          right: 108px;
          top: -16px;
          width: 69px;
        }
        img:nth-child(2){
          left: 40px;
          bottom: -10px;
          width: 88px;
        }
        img:nth-child(3){
          right: -13px;
          bottom: 30px;
          width: 97px;
          @media screen and (max-width: 800px) {
            bottom: -20px;
            right: -13px;
            width: 97px;
          }
        }
      }

      &:last-child{
        img{
          margin-left: -10px;
          @media screen and (max-width: 900px) {
            width: 100%;
          }
        }
      }
    }
  }

  .what-more{
    background: #FFFFFF;
    border-radius: 38px;
    padding: 40px 33px;
    font-size: 20px;
    line-height: 25px;
    color: #152337;
    letter-spacing: 1px;
    @media screen and (max-width: 700px) {
      padding: 30px;
      b{
        letter-spacing: 0.5px;
      }
    }

    .what-more-people{
      position: relative;
      display: block;
      margin-top: 31px;
      padding-left: 148px;
      @media screen and (max-width: 700px) {
        padding-left: 0;
      }
      img{
        position: absolute;
        display: block;
        width: 121px;
        left: 0;
        top: 0;
        @media screen and (max-width: 700px) {
          position: relative;
          margin-bottom: 0px;
        }
      }
      b{
        position: relative;
        display: block;
        font-size: 22px;
        line-height: 24px;
        color: #0A4DAA;
        padding-top: 10px;
        margin-bottom: 6px;
      }
      small{
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: #757575;
      }
    }
  }

  .complete-items{
    width: 844px;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(104px - 47px);
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    @media screen and (max-width: 700px) {
      display: block;
      margin-left: -15px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: calc(100% + 30px);
      height: 450px;
      margin-bottom: -70px;
      &::-webkit-scrollbar{
        opacity: 0;
        visibility: hidden;
      }
    }

    .complete-item{
      width: 387px;
      height: 217px;
      background: #FFFFFF;
      border-radius: 38px;
      padding: 23px 44px;
      //padding-left: 44px;
      font-size: 20px;
      line-height: 25px;
      color: #152337;
      font-weight: bold;
      letter-spacing: 1px;
      box-sizing: border-box;

      span{
        position: relative;
        display: inline-block;
        font-size: 14px;
        line-height: 30px;
        background: #0A4DAA;
        color: #fff;
        height: 30px;
        border-radius: 15px;
        padding: 0 15px;
        margin-bottom: 10px;
      }

      a.yellow-btn{
        position: absolute;
        left: 0;
        bottom: -83px;
        &:hover{
          opacity: 0.7 !important;
        }
      }

      @media screen and (max-width: 900px) {
        width: 48%;
      }
      @media screen and (max-width: 700px) {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        padding: 30px;
        width: 313px;
        height: 266px;
        font-size: 21px;
        line-height: 25px;
        margin-right: 15px;
        &:first-child{
          margin-left: 15px;
        }
      }

      img{
        display: block;
        position: absolute;
        bottom: -63px;
        right: -40px;
        width: 182px;
      }

      &:last-child{
        img{
          bottom: -96px;
          right: -2px;
          width: 136px;
        }
      }
    }
  }
  .patient-info{
    position: relative;
    display: block;
    margin: 30px 45px 21px;
    @media screen and (max-width: 900px) {
      margin: 24px 16px 16px;
    }
    &.patient-bad-info{
      margin: 30px 0 27px;
      color: #fff;
      @media screen and (max-width: 700px) {
        margin-top: 0;
        margin-bottom: 20px;
        position: absolute;
        top: 10px;
      }

      img{
        height: 98px;
        margin-right: 10px;
      }
      .patient-info-text{
        span{
          background: rgba(255, 255, 255, 0.21);
        }
      }
    }
    img{
      display: inline-block;
      vertical-align: middle;
      height: 123px;
      margin-right: 15px;
      @media screen and (max-width: 900px) {
        height: 106px;
        margin-right: 20px;
      }
    }
    .patient-info-text{
      position: relative;
      display: inline-block;
      vertical-align: middle;
      b{
        font-weight: bold;
        font-size: 30px;
        line-height: 47px;
        @media screen and (max-width: 900px) {
          font-size: 26px;
          line-height: 31px;
        }
      }
      span{
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 40px;
        border-radius: 20px;
        font-weight: 300;
        background: #0A4DAA;
        color: #fff;
        padding: 0 15px;
        @media screen and (max-width: 900px) {
          font-size: 16px;
          margin-top: 8px;
        }
      }
    }
  }

  .video-block{
    position: relative;
    display: block;
    width: 100%;
    height: 362px;
    border-radius: 30px;
    background: #fff;
    @media screen and (max-width: 700px) {
      height: auto;
    }

    #video-fish{
      position: absolute;
      right: -47px;
      bottom: -67px;
      @media screen and (max-width: 700px) {
        width: 91px;
        right: -8px;
        bottom: -21px;
      }

    }
    .left-video{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      width: calc(100% - 254px);
      height: 100%;
      background: url(../img/video.png) no-repeat 50% 50%;
      background-size: cover;
      border-radius: 30px 0 0 30px;
      img{
        cursor: pointer;
        &:hover{
          opacity: 0.7;
        }
      }
      @media screen and (max-width: 700px) {
        position: relative;
        width: 100%;
        height: 192px;
        border-radius: 30px 30px 0 0;
      }
    }
    .right-video{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      width: 254px;
      height: 100%;
      @media screen and (max-width: 700px) {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        padding-top: 16px;
        padding-bottom: 36px;
        padding-left: 0;
        padding-right: 41px;
        box-sizing: border-box;
      }

      .right-video-content{
        position: relative;
        display: block;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #757575;
        text-align: center;
        @media screen and (max-width: 700px) {
          height: auto;
          margin-left: 16px;
          padding-left: 72px;
          text-align: left;
          box-sizing: border-box;
          br{
            display: none;
          }
          img{
            position: absolute;
            display: block;
            width: 60px;
            top: 0;
            left: 0;
          }
        }
        b{
          position: relative;
          display: block;
          margin-bottom: 8px;
          margin-top: 12px;
          letter-spacing: 0.5px;
          color: #152337;
          font-size: 16px;
          @media screen and (max-width: 700px) {
            margin-top: 0;
          }

        }
      }
    }
  }

  #question{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 90px;
    @media screen and (max-width: 900px) {
      display: block;
      padding-bottom: 150px;
    }

    #left-btn, #right-btn{
      position: relative;
      display: block;
      width: 110px;
      height: 110px;
      border-radius: 110px;
      background: #E4DE7D;
      cursor: pointer;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      .lr-btn-text{
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 20px;
        font-size: 10px;
        line-height: 10px;
        top: 75px;
        text-align: center;
        font-weight: 500;
        @media screen and (max-width: 900px) {
          font-size: 8px;
          line-height: 8px;
          top: 70px;
        }
      }
      &:after{
        position: absolute;
        display: block;
        content: "";
        left: -10px;
        top: -10px;
        width: 100%;
        height: 100%;
        border-radius: 110px;
        border: #FFFFFF solid 10px;
        opacity: 0.3;
      }
    }

    #left-btn{
      @media screen and (max-width: 900px) {
        height: 100px;
        width: 100px;
        position: absolute;
        //top: 540px;
        top: auto;
        bottom: 10px;
        left: calc(50% - 120px);
      }
    }
    #right-btn{
      @media screen and (max-width: 900px) {
        height: 100px;
        width: 100px;
        position: absolute;
        //top: 540px;
        top: auto;
        bottom: 10px;
        left: calc(50% + 20px);
      }
    }

    .question-content-back{
      position: absolute;
      display: block;
      width: 458px;
      height: 542px;
      left: 68px;
      top: 53px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 43px;
      @media screen and (max-width: 900px) {
        width: calc(100% - 30px);
        height: calc(100vh - 378px);
        min-height: 370px;
        top: 55px;
        left: 15px;
      }
    }
    .question-content-back2{
      position: absolute;
      display: block;
      width: 522px;
      height: 558px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 43px;
      left: 36px;
      top: 24px;
      @media screen and (max-width: 900px) {
        width: calc(100% - 10px);
        //height: 400px;
        height: calc(100vh - 350px);
        min-height: 400px;
        top: 17px;
        left: 5px;
      }
    }

    .question-content{
      position: relative;
      display: block;
      width: 594px;
      height: 567px;
      @media screen and (max-width: 900px) {
        width: 100%;
        //height: 405px;
        min-height: 405px;
        height: calc(100vh - 345px);
      }
      .question-number{
        position: absolute;
        display: block;
        top: -31px;
        left: 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 23px;
        color: #fff;
      }
      .question-content-front{
        position: relative;
        display: block;
        width: 594px;
        height: 567px;
        left: 0;
        top: 0;
        background: #fff;
        border-radius: 43px;
        overflow:hidden;
        transition: all 300ms ease-out;
        @media screen and (max-width: 900px) {
          width: 100%;
          //height: 405px;
          min-height: 405px;
          height: calc(100vh - 345px);
        }
        &.left-rotation{
          transform: rotate(-5deg);
          left: -30px;
          top: -30px;
        }
        &.right-rotation{
          transform: rotate(5deg);
          left: 30px;
          top: -30px;
        }
        &.full-left-rotation{
          transition: all 300ms ease-in;
          transition-delay: 200ms;
          transform: rotate(-15deg);
          left: -1000px;
          top: -50px;
          opacity: 0;
        }
        &.full-right-rotation{
          transition: all 300ms ease-in;
          transition-delay: 200ms;
          transform: rotate(15deg);
          left: 1000px;
          top: -50px;
          opacity: 0;
        }

        .question-text{
          position: absolute;
          display: block;
          bottom: 0;
          width: 100%;
          height: 152px;
          box-sizing: border-box;
          padding: 28px 37px 0 51px;
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: .5px;
          background: #F1F1F1;
          @media screen and (max-width: 900px) {
            padding: 24px 19px 14px;
            height: 117px;
            font-size: 14px;
            line-height: 16px;
          }
          span{
            position: absolute;
            display: block;
            left: 51px;
            width: 100px;
            height: 40px;
            top: -20px;
            border-radius: 20px;
            font-size: 18px;
            line-height: 40px;
            background: #0A4DAA;
            text-align: center;
            color: #fff;
            font-weight: 300;
            @media screen and (max-width: 900px) {
              left:19px;
              font-size: 16px;
            }
          }
        }


        .question-info{
          .text-block{
            width: 525px;
            padding-left: 53px;
            @media screen and (max-width: 900px) {
              padding-left: 19px;
              width: calc(100% - 32px);

            }
          }
          .text-block-content {
            max-height: 200px;
            padding-right: 55px;
            @media screen and (max-width: 900px) {
              padding-right: 33px;
              min-height: 108px;
              max-height: calc(100vh - 635px);
            }
            .text-block-content-data{
              font-size: 20px;
              line-height: 25px;
              color: #152337;
              padding-bottom: 40px;
              @media screen and (max-width: 900px) {
                font-size: 16px;
                line-height: 18px;
              }

              strong{
                position: relative;
                display: block;
                color: #0A4DAA;
                letter-spacing: 1px;
                margin-top: 10px;
              }
              small{
                position: relative;
                display: block;
                font-size: 12px;
                line-height: 15px;
                margin-top: 20px;
              }

              sup{
                position: relative;
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                top:-3px;
              }
            }
          }
          .text-block-scrollbar{
            background: url(../img/scrollbar_black.svg) repeat-y 26px 0;
            background-size: 3px 100%;
            @media screen and (max-width: 700px) {
              background-size: 3px 210%;
            }
          }
        }
      }

      .question-content-bad{
        position: absolute;
        display: block;
        width: 594px;
        height: 567px;
        left: 0;
        top: 30px;
        opacity: 0;
        visibility: hidden;
        background: rgba(247, 106, 61, 0.96) url(../img/bad.svg) no-repeat 50% 14%;
        border-radius: 43px;
        transition: all 300ms ease-out;
        @media screen and (max-width: 900px) {
          width: 100%;
          height: 405px;
          background-size: auto 47.6%;
        }
        &.active{
          top: 0;
          opacity: 1;
          visibility: visible;
        }
        span{
          position: absolute;
          display: none;
          top: 386px;
          font-size: 69px;
          line-height: 69px;
          width: 100%;
          text-align: center;
          color: #fff;
          font-weight: 700;
          text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.22);
          b{
            //font-family: Arial;
            font-size: 63px;
          }
          @media screen and (max-width: 900px) {
            font-size: 50px;
            line-height: 47px;
            top: calc(64% - 25px);
            b{
              //font-family: Arial;
              font-size: 47px;
            }
          }
        }
        a{
          display: block;
          position: absolute;
          top: 429px;
          left: calc(50% - 98px);

          @media screen and (max-width: 900px) {
            display: block;
            left: 0;
            position: absolute;
            margin-top: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 41px;
            opacity: 0;
          }
        }

        .question-content-bad-text-item{
          display: none;
          position: absolute;
          width: 297px;
          height: 250px;
          top: 26px;
          left: 389px;
          border-radius: 38px;
          background: #fff;
          padding-top: 39px;
          padding-left: 42px;
          box-sizing: border-box;
          font-size: 30px;
          line-height: 35px;
          letter-spacing: 1px;
          color: #8E8E8E;
          font-style: italic;
          white-space: nowrap;
          @media screen and (max-width: 900px) {
            font-size: 21px;
            height: auto;
            left: 22px;
            line-height: 22px;
            padding: 25px 25px 55px;
            width: 216px;
            top: auto;
            bottom: -40px;
          }
          img{
            position: absolute;
            display: block;
            @media screen and (max-width: 900px) {
              transform: scale(0.5);
            }
          }
          &:first-child{
            img{
              width: 340px;
              left: -90px;
              top: 155px;
              @media screen and (max-width: 900px) {
                left: -104px;
                top: 80px;
              }
            }
          }
          &:nth-child(2){
            img{
              left: -81px;
              top: 105px;
              width: 333px;
              @media screen and (max-width: 900px) {
                left: -121px;
                top: 5px;
              }
            }
          }
          &:nth-child(3){
            img{
              left: -49px;
              top: 127px;
              width: 248px;
              @media screen and (max-width: 900px) {
                left: -84px;
                top: 24px;
              }
            }
          }
          &:nth-child(4){
            img{
              left: -69px;
              top: 147px;
              width: 330px;
              @media screen and (max-width: 900px) {
                left: -104px;
                top: 28px;
              }
            }
          }
        }

        &.left-rotation{
          transform: rotate(-5deg);
        }
        &.right-rotation{
          transform: rotate(5deg);
        }
        &.full-left-rotation{
          transition: all 500ms ease-in;
          transform: rotate(-15deg);
          left: -1000px;
          top: -50px;
          opacity: 0;
        }
        &.full-right-rotation{
          transition: all 500ms ease-in;
          transform: rotate(15deg);
          left: 1000px;
          top: -50px;
          opacity: 0;
        }
      }

      .question-content-good{
        position: absolute;
        display: block;
        width: 594px;
        height: 567px;
        left: 0;
        top: 30px;
        opacity: 0;
        visibility: hidden;
        background: rgba(45, 216, 238, 0.96) url(../img/good.svg) no-repeat 50% 17%;
        border-radius: 43px;
        transition: all 300ms ease-out;
        @media screen and (max-width: 900px) {
          width: 100%;
          height: 405px;
          background-size: auto 47.6%;
        }
        &.active{
          top: 0;
          opacity: 1;
          visibility: visible;
        }
        span{
          position: absolute;
          display: none;
          top: 386px;
          font-size: 69px;
          line-height: 69px;
          width: 100%;
          text-align: center;
          color: #fff;
          font-weight: 600;
          text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.22);
          @media screen and (max-width: 900px) {
            font-size: 50px;
            line-height: 47px;
            top: calc(64% - 16px);
          }
          &:first-child{
            display: block;
          }
        }
        a{
          display: block;
          position: absolute;
          top: 429px;
          left: calc(50% - 98px);

          @media screen and (max-width: 900px) {
            display: block;
            left: 0;
            position: absolute;
            margin-top: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border-radius: 41px;
            opacity: 0;
          }
        }

        .question-content-good-text-item{
          display: none;
          position: absolute;
          width: 297px;
          height: 228px;
          top: 26px;
          left: 389px;
          border-radius: 38px;
          background: #fff;
          padding-top: 39px;
          padding-left: 42px;
          box-sizing: border-box;
          font-size: 30px;
          line-height: 35px;
          letter-spacing: 1px;
          color: #8E8E8E;
          font-style: italic;
          @media screen and (max-width: 900px) {
            font-size: 21px;
            height: auto;
            left: 22px;
            line-height: 22px;
            padding: 25px 25px 55px;
            width: 216px;
            top: auto;
            bottom: -40px;
          }
          img{
            position: absolute;
            display: block;
            @media screen and (max-width: 900px) {
              transform: scale(0.5);
            }
          }
          &:first-child{
            img{
              left: -60px;
              top: 125px;
              width: 226px;
              @media screen and (max-width: 900px) {
                left: 57px;
                top: 28px;
              }
            }
          }
          &:nth-child(2){
            img{
              left: -162px;
              top: 52px;
              width: 567px;
              @media screen and (max-width: 900px) {
                left: -104px;
                top: -62px;
              }
            }
          }
          &:nth-child(3){
            img{
              left: -89px;
              top: 142px;
              width: 371px;
              @media screen and (max-width: 900px) {
                left: -14px;
                top: 28px;
              }
            }
          }
          &:nth-child(4){
            img{
              left: -80px;
              top: 77px;
              width: 308px;
              @media screen and (max-width: 900px) {
                left: -4px;
                top: -2px;
              }
            }
          }
        }

        &.left-rotation{
          transform: rotate(-5deg);
        }
        &.right-rotation{
          transform: rotate(5deg);
        }
        &.full-left-rotation{
          transition: all 500ms ease-in;
          transform: rotate(-15deg);
          left: -1000px;
          top: -50px;
          opacity: 0;
        }
        &.full-right-rotation{
          transition: all 500ms ease-in;
          transform: rotate(15deg);
          left: 1000px;
          top: -50px;
          opacity: 0;
        }
      }
    }
  }

  &.iexplorer .text-block-scrollbar{
    display: none;
  }

  #rotate-device{
    position: fixed;
    display: none;
    z-index: 10000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url(../img/bg_coral.png) no-repeat 50% 50%;
    background-size: cover;
    font-size: 15px;
    @media screen and (orientation:landscape) and (max-height: 450px) {
      display: flex;
    }

    .rotate-device-field{
      position: relative;
      display: block;
      width: 360px;
      height: 200px;
      border-radius: 38px;
      background: #fff;
      padding: 36px;
      box-sizing: border-box;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      color: #152337;
      letter-spacing: 1px;

      img{
        margin-bottom: 24px;
      }

      .rotate-fish{
        position: absolute;
        display: block;
        width: 120px;
        top: -5px;
        right: -42px;
      }
    }

  }



}




