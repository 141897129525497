@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Light.woff2') format('woff2'),
        url('../fonts/GothamPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.woff2') format('woff2'),
        url('../fonts/GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Medium.woff2') format('woff2'),
        url('../fonts/GothamPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

